<template>
  <div>
    <div class="collections-actions">
      <base-button
          size="sm"
          outline
          type="primary"
          class="collections-action-button"
          @click="copyIframeLink()"
      ><i class="fa fa-link" />Public Collection</base-button
      >
    </div>
        <!-- Branding Section -->
      <base-header
        class="pb-6 content__title content__title--calendar"
        type="base"
      />
    <!-- Event Collections -->
    <base-collections></base-collections>
  </div>
</template>

<script>
import BaseCollections from "@/components/Collections/BaseCollections.vue";
import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
export default {
  layout: "CollectionsPage",
  components: {
    BaseCollections
  },
  data() {
    return {
      user: null,
    };
  },
  created() {
    this.auth();
  },
  methods: {   
    async auth() {
      try {
        // Retrieve User data
        this.user = { ...this.$store.getters["profile/me"] };
      } catch (error) {
        // Notification
        this.$notify({
          type: "danger",
          message: "Oops, something went wrong with your account!",
        });
        this.setApiValidation(error.response.data.errors);
      }
    },
    async copyIframeLink() {      
      if (this.user) {
        const publicURL = process.env.VUE_APP_BASE_URL +
            '/collections/' +
            this.user.uuid;
         const iFrameCodeblock =
          '<iframe src=' + publicURL +
          ' width=100%; height=1400px; style=border:none;' +
          '></iframe>';
        const copyFirstLink = "'function copyURL(){ navigator.clipboard.writeText(\""+iFrameCodeblock+"\").then(function(){alert(\"Collection as iFrame - Copied, ready to share it!\")},function(){alert(\"Collection as iFrame - failed to copy!\")}); };copyURL()'"
        const copySecondLink = "'function copyURL(){ navigator.clipboard.writeText(\""+publicURL+"\").then(function(){alert(\"Collection as Direct Link - Copied, ready to share it!\")},function(){alert(\"Collection as Direct Link - failed to copy!\")}); };copyURL()'"

        await swal.fire({
          title: "Public Collection",
          html:
              "You can use the below iframe or direct link to incorporate your entire events Collection to any website, your social media or as web widget etc.<br></hr> " +
              "<br><b>as embed iFrame:</b><br> <button onclick=" + copyFirstLink + "type='button' class='btn-icon-clipboard text-center'>" +
              "<'" + "iframe src='https://app.bookmeinscotty.com/...' width='100%' height='580em' style='border:none;'" + '><' + "'/iframe'" + ">" +
              "</button> <br> <b>or by direct Link:</b></hr></b><br> <span onclick=" + copySecondLink + " type='button' class='btn-icon-clipboard text-center'>" +
              publicURL +
              "</span><br></hr> <small class='text-muted text-uppercase text-xs text-black-50'> <i class='ni ni-single-copy-04'/>  Click-to-Copy</small>",
          type: "success",
          confirmButtonClass: "btn btn-outline-danger btn-fill",
          confirmButtonText: " Close",
          buttonsStyling: false,
          icon: "success",
        });
      }
    },
  }
};
</script>
